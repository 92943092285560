<template>
<div class="mb-6">
  <v-app-bar :height="100">
       <v-img
        src="../img/CampItLogo.png"
        max-height="70"
        max-width="250"
        @click="$router.push('/')"
      ></v-img>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="loggedIn" class="hidden-xs-only">
        <v-row>
          <v-col :cols=4 md="6" class="nav__button">
            <v-select 
              :items="navOptions"
              item-text="name"
              item-value="route"
              :label="currentRoute"
              @change="changedRoute"
              class="mt-4"
              dense
              solo
            >
            </v-select>
          </v-col>
          <v-col :cols=3 md="3" class="register-btn">
            <v-btn
              class="navBtn mt-4"
              @click="logout()">
              Logout
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar-items>
      <v-toolbar-items v-else >
        <v-row>
          <v-col :cols=12 md="8" class="register-btn">
            <v-btn
              v-for="item in menuItems"
              :key="item.title"
              class="navBtn mt-4"
              @click="login(item.title)">
              {{ item.title }}
            </v-btn>
          </v-col>
          <v-col :cols=12>
            <p @click="register()">Register</p>
          </v-col>
        </v-row>
      </v-toolbar-items>
    </v-app-bar>
     <v-dialog
      v-model="logingIn"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{loginType}} 
        </v-card-title>

        <v-card-text>
          <Account
            :register="IsRegistering"
            :isManager="isManager"
            @toggle-register="IsRegistering=$event"
            @keep-modal-open="logingIn=$event"
          ></Account>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
</div>
  
</template>

<script>
import AuthService from '@/api-services/auth.service.js'
import Account from '@/views/Account.vue'

export default {
  name: 'Navbar',
  components: {
    'Account': Account
  },
  props: ['isManager'],
  data () {
    return {
      appTitle: 'CampIt',
      sidebar: true,
      // loggedIn: this.$store.state.loggedIn,
      // loggedIn: localStorage.getItem('myCamp') ? true : false,
      logingIn: false,
      loginType: 'Parent Login',
      IsRegistering: false,
      navOptions: [
        {name: 'Search/Home', route: '/'},
        {name: 'Session Management', route: '/camp'},
        {name: 'Camp Profile', route: '/managecamp'}
        ],
      navigateMsg: 'Home',
      menuItems: [
          // { title: 'Parent Login', type: 'Parent' },
          { title: 'Camp Login', type: 'Camp' }
         
     ]
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn
    },
    currentRoute(){
      const route = this.navOptions.find(el => {
        return el.route === this.$route.path
      })
      return route?.name ? route.name : "Navigate"
    }
  },
  methods: {
    async logout() {
      let logout =  AuthService.logout();
      await logout.then( () => {
        this.$cookies.remove('cit')
        this.$cookies.remove('rft')
        this.$store.commit('updateLoggedIn', false)
        localStorage.clear()
        this.$router.push('/')
      });
    },
    login(type) {
      //if valid jwt - go to dashboard
        this.loginType = type
        this.logingIn = true
      //else bring up modal to input credentials 
    },
    register() {
      this.IsRegistering = true
      this.logingIn = true
        this.loginType = "Register"
    },
    changedRoute(route) {
      this.$router.push(route)
    },
  },
}
</script>
<style scoped>
 #navbar {
   height: 200px;
   background-color: #5E8E2F;
 }

 .navbar {
   height: 400px;
 }

.v-btn {
  background-color: #53812B !important;
  border-radius: 3px;
  color: #ffffff;
  margin: 10px 10px 0 10px;
}

.register-btn, .nav__button {
  padding-bottom: 0;
}

.profile--icon{
  margin: 10px 10px 0 10px;

}
</style>